import React, { useState } from "react";
import { Navbar } from "react-bootstrap";
import "./TitleHeader.styles.css";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@material-ui/icons/RemoveCircleOutlineRounded";

export const TitleHeaderSub = ({ SubTitleInfo }) => {
  return (
    <Navbar bg="dark" className="titleNavSub">
      <Navbar.Brand>
        <span className="title">{SubTitleInfo}</span>
      </Navbar.Brand>
    </Navbar>
  );
};
