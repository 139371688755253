import React, { useState } from "react";
import { Form, Row, Col, Button, Container, Card } from "react-bootstrap";
import "./Enrollment.styles.css";
import { TitleHeader } from "../Common/TitleHeader";
import { PersonalInfo } from "./PersonalInfo";
import { FamilyDetails } from "../Enrollment/FamilyDetails";
import { ContactInfo } from "./ContactInfo";

export const Enrollment = () => {
  const [showPersonalInfo, setShowPersonalInfo] = useState(true);
  const [showContactInfo, setShowContactInfo] = useState(true);
  const [showFamilyInfo, setShowFamilyInfo] = useState(true);
  return (
    <Container>
      <Form>
        <Row onClick={() => setShowPersonalInfo(!showPersonalInfo)}>
          <Col>
            <TitleHeader
              TitleInfo="Personal Info"
              showPopUp={showPersonalInfo}
            />
          </Col>
        </Row>
        {showPersonalInfo && (
          <Row>
            <Col>
              <Card>
                <PersonalInfo />
              </Card>
            </Col>
          </Row>
        )}
        <Row onClick={() => setShowFamilyInfo(!showFamilyInfo)}>
          <Col>
            <TitleHeader
              TitleInfo="Family Details"
              showPopUp={showFamilyInfo}
            />
          </Col>
        </Row>
        {showFamilyInfo && (
          <Row>
            <Col>
              <Card>
                <FamilyDetails />
              </Card>
            </Col>
          </Row>
        )}
        <Row onClick={() => setShowContactInfo(!showContactInfo)}>
          <Col>
            <TitleHeader TitleInfo="Contact Info" showPopUp={showContactInfo} />
          </Col>
        </Row>
        {showContactInfo && (
          <Row>
            <Col>
              <Card>
                <ContactInfo />
              </Card>
            </Col>
          </Row>
        )}
        <Card>
          <div className="buttonPanel">
            <Button variant="primary" type="submit" className="buttonData">
              Submit
            </Button>
            <Button Variant="primary" type="cancel" className="buttonData">
              Cancel
            </Button>
          </div>
        </Card>
      </Form>
    </Container>
  );
};
