import React, { useState } from "react";
import { Form, Button, Container, Col } from "react-bootstrap";
import moment from "moment";
import "./Enrollment.styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TitleHeader } from "../Common/TitleHeader";
import { string as yupString, object as yupObject } from "yup";

import { useFormik } from "formik";
export const FamilyMember = ({ selectedMember, index }) => {
  const [PhyAddress, SetPhyAddress] = useState("Workshop corner");
  const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const selectedMemberFirstName = `FirstName_${selectedMember}_${index}`;
  const selectedMemberLastName = `LastName_${selectedMember}_${index}`;
  const selectedMemberOccupation = `Occupation${selectedMember}_${index}`;
  const selectedMemberGrossIncome = `GrossIncome${selectedMember}_${index}`;

  const validationSchema = yupObject().shape({
    selectedMemberFirstName: yupString()
      .min(3, "Too Short")
      .max(20, "Too Long")
      .required("*First Name Is Required"),
    LastName: yupString()
      .min(3, "Too Short")
      .max(20, "Too Long")
      .required("*Last Name Is Required"),
    Education: yupString()
      .min(3, "Too Short")
      .max(20, "Too Long")
      .required(" *Education Is Required"),
    Profession: yupString()
      .min(3, "Too Short")
      .max(20, "Too Long")
      .required(" *Profession Is Required"),
    GrossIncome: yupString()
      .matches(/^\d+$/, "Income must be In Digit")
      .required("*Income Is Required"),
  });

  const formik = useFormik({
    initialValues: {},
    validationSchema,
  });
  return (
    <Container>
      <Form>
        <Form.Row>
          <Col lg={3}>
            <Form.Control
              id={selectedMemberFirstName}
              type="text"
              placeholder={`Enter ${selectedMember} Name`}
              value={formik.values[selectedMemberFirstName]}
              maxLength={21}
              onChange={(e) =>
                formik.setFieldValue(selectedMemberFirstName, e.target.value)
              }
              onBlur={formik.handleBlur}
              isInvalid={
                formik.touched[selectedMemberFirstName] &&
                formik.errors[selectedMemberFirstName]
              }
            />
            {formik.touched[selectedMemberFirstName] &&
              formik.errors[selectedMemberFirstName] && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors[selectedMemberFirstName]}
                </Form.Control.Feedback>
              )}
            <Form.Text className="text-muted"></Form.Text>
          </Col>
          <Col lg={3}>
            <Form.Control
              id={selectedMemberLastName}
              type="text"
              placeholder={`Enter ${selectedMember} Last Name`}
              value={formik.values[selectedMemberLastName]}
              maxLength={21}
              onChange={(e) =>
                formik.setFieldValue(selectedMemberLastName, e.target.value)
              }
              onBlur={formik.handleBlur}
              isInvalid={
                formik.touched[selectedMemberLastName] &&
                formik.errors[selectedMemberLastName]
              }
            />
            {formik.touched[selectedMemberLastName] &&
              formik.errors[selectedMemberLastName] && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors[selectedMemberLastName]}
                </Form.Control.Feedback>
              )}
            <Form.Text className="text-muted"></Form.Text>
          </Col>
          <Col lg={3}>
            <Form.Control
              id={selectedMemberOccupation}
              type="text"
              placeholder={`Enter ${selectedMember} Occupation`}
              value={formik.values[selectedMemberOccupation]}
              maxLength={21}
              onChange={(e) =>
                formik.setFieldValue(selectedMemberOccupation, e.target.value)
              }
              onBlur={formik.handleBlur}
              isInvalid={
                formik.touched[selectedMemberOccupation] &&
                formik.errors[selectedMemberOccupation]
              }
            />
            {formik.touched[selectedMemberOccupation] &&
              formik.errors[selectedMemberOccupation] && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors[selectedMemberOccupation]}
                </Form.Control.Feedback>
              )}
            <Form.Text className="text-muted"></Form.Text>
          </Col>
          <Col lg={3}>
            <Form.Control
              id={selectedMemberGrossIncome}
              type="text"
              placeholder={`Enter ${selectedMember} Gross Income`}
              value={formik.values[selectedMemberGrossIncome]}
              maxLength={21}
              onChange={(e) =>
                formik.setFieldValue(selectedMemberGrossIncome, e.target.value)
              }
              onBlur={formik.handleBlur}
              isInvalid={
                formik.touched[selectedMemberGrossIncome] &&
                formik.errors[selectedMemberGrossIncome]
              }
            />
            {formik.touched[selectedMemberGrossIncome] &&
              formik.errors[selectedMemberGrossIncome] && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors[selectedMemberGrossIncome]}
                </Form.Control.Feedback>
              )}
            <Form.Text className="text-muted"></Form.Text>
          </Col>
        </Form.Row>
      </Form>
    </Container>
  );
};
