import React, { useState } from "react";
import { Form, Button, Container, Col, Row } from "react-bootstrap";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { string as yupString, object as yupObject } from "yup";
import "./Enrollment.styles.css";
import { TitleHeaderSub } from "../Common/TitleHeaderSub";
import { useFormik } from "formik";
export const Address = ({ SubTitleInfo }) => {
  const [PhyAddress, SetPhyAddress] = useState("Workshop corner");

  const validationSchema = yupObject().shape({
    address: yupString()
      .min(3, "Too Short")
      .max(50, "Too Long")
      .required("*Permenent Address Is Required"),
  });

  const formik = useFormik({
    initialValues: {},
    validationSchema,
  });

  return (
    <Container>
      <Row>
        <Col>
          <TitleHeaderSub SubTitleInfo={SubTitleInfo} />
        </Col>
      </Row>

      <Row>
        <Col lg={4}>
          <Form.Group controlId="address">
            <Form.Label>Location</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Your Permenent Address"
              value={formik.values.address}
              onChange={(e) => formik.setFieldValue("address", e.target.value)}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.address && formik.errors.address}
            />
            {formik.touched.address && formik.errors.address && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.address}
              </Form.Control.Feedback>
            )}
            <Form.Text className="text-muted"></Form.Text>
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group controlId="physicaladd">
            <Form.Label>Land Mark</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Your Physical Address"
              value={PhyAddress}
              onChange={(e) => SetPhyAddress(e.target.value)}
              onFocus={() => SetPhyAddress("")}
            />
            <Form.Text className="text-muted"></Form.Text>
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group controlId="physicaladd">
            <Form.Label>Area</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Your Physical Address"
              value={PhyAddress}
              onChange={(e) => SetPhyAddress(e.target.value)}
              onFocus={() => SetPhyAddress("")}
            />
            <Form.Text className="text-muted"></Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <Form.Group controlId="city">
            <Form.Label>City</Form.Label>
            <Form.Control as="select">
              <option>Pune</option>
              <option>Mumbai</option>
              <option>Nanded</option>
              <option>Nagpur</option>
              <option>Kolhapur</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group controlId="city">
            <Form.Label>State</Form.Label>
            <Form.Control as="select">
              <option>Maharashtra</option>
              <option>Telangana</option>
              <option>Gujrat</option>
              <option>MP</option>
              <option>Karnataka</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group controlId="city">
            <Form.Label>Country</Form.Label>
            <Form.Control as="select">
              <option>India</option>
              <option>Others</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row />
    </Container>
  );
};
