import React, { useState } from "react";
import { Form, Button, Container, Col, Row } from "react-bootstrap";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { string as yupString, object as yupObject } from "yup";
import "./Enrollment.styles.css";
import { Address } from "./Address";

import { useFormik } from "formik";
export const ContactInfo = () => {
  const [PhyAddress, SetPhyAddress] = useState("Workshop corner");
  const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  const validationSchema = yupObject().shape({
    email: yupString()
      .email("*Must be a valid email address")
      .max(100, "*Email must be less than 100 characters")
      .required("*Email is required"),
    ContactNo: yupString()
      .matches(phoneRegExp, "*Phone number is not valid")
      .required("*Phone number required"),
    address: yupString()
      .min(3, "Too Short")
      .max(50, "Too Long")
      .required("*Permenent Address Is Required"),
  });

  const formik = useFormik({
    initialValues: {},
    validationSchema,
  });

  return (
    <Container>
      <Row>
        <Col lg={6}>
          <Form.Group controlId="email">
            <Form.Label>Email ID</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter email"
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.email && formik.errors.email}
            />

            {formik.touched.email && formik.errors.email && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group controlId="ContactNo">
            <Form.Label>Contact No</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Your Contact No"
              maxLength={13}
              value={formik.values.ContactNo}
              onChange={(e) =>
                formik.setFieldValue("ContactNo", e.target.value)
              }
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.ContactNo && formik.errors.ContactNo}
            />
            {formik.touched.ContactNo && formik.errors.ContactNo && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.ContactNo}
              </Form.Control.Feedback>
            )}
            <Form.Text className="text-muted"></Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Address SubTitleInfo="Permanent Address" />
      </Row>

      <Row>
        <Address SubTitleInfo="Residential Address" />
      </Row>
    </Container>
  );
};
