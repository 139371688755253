import React, { useState } from "react";
import { Navbar } from "react-bootstrap";
import "./TitleHeader.styles.css";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@material-ui/icons/RemoveCircleOutlineRounded";

export const TitleHeader = ({ TitleInfo, showPopUp }) => {
  return (
    <Navbar bg="dark" className="titleNav">
      {!showPopUp ? (
        <AddCircleOutlineRoundedIcon className="titleIcon" />
      ) : (
        <RemoveCircleOutlineRoundedIcon className="titleIcon" />
      )}
      <Navbar.Brand>
        <span className="title">{TitleInfo}</span>
      </Navbar.Brand>
    </Navbar>
  );
};
