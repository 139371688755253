import React, { useState } from "react";
import { Form, Button, Container, Col } from "react-bootstrap";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { string as yupString, object as yupObject } from "yup";
import "./Enrollment.styles.css";

import { useFormik } from "formik";
export const PersonalInfo = () => {
  const [PhyAddress, SetPhyAddress] = useState("Workshop corner");
  const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  const validationSchema = yupObject().shape({
    FirstName: yupString()
      .min(3, "Too Short")
      .max(20, "Too Long")
      .required("*First Name Is Required"),
    LastName: yupString()
      .min(3, "Too Short")
      .max(20, "Too Long")
      .required("*Last Name Is Required"),
    age: yupString()
      .matches(/^\d+$/, "Age must be In Digit")
      .min(18, "You must be at least 18 years")
      //.max(60, "You must be at most 60 years")
      .required("*Age Is Required"),
    placeofbirth: yupString()
      .min(3, "Too Short")
      .max(15, "Too Long")
      .required("*Place Of Birth Required"),
    weight: yupString()
      .matches(/^\d+$/, "weight must be In Digit")
      .required("*Weight Is Required"),
    height: yupString()
      .matches(/^\d+$/, "Height must be In Digit")
      .required("*Height Is Required"),
    chest: yupString()
      .matches(/^\d+$/, "Chest must be In Digit")
      .required("*chest Is Required"),
  });

  const formik = useFormik({
    initialValues: {},
    validationSchema,
  });

  return (
    <div>
      <Container>
        <Form>
          <Form.Row>
            <Form.Group as={Col} controlId="FirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter First Name"
                value={formik.values.FirstName}
                maxLength={21}
                onChange={(e) =>
                  formik.setFieldValue("FirstName", e.target.value)
                }
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.FirstName && formik.errors.FirstName}
              />
              {formik.touched.FirstName && formik.errors.FirstName && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.FirstName}
                </Form.Control.Feedback>
              )}
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>

            <Form.Group as={Col} controlId="LastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Last Name"
                value={formik.values.LastName}
                maxLength={21}
                onChange={(e) =>
                  formik.setFieldValue("LastName", e.target.value)
                }
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.LastName && formik.errors.LastName}
              />
              {formik.touched.LastName && formik.errors.LastName && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.LastName}
                </Form.Control.Feedback>
              )}
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Col lg={4}>
              <Form.Group as={Col} controlId="dateofbirth">
                <Form.Label>Date Of Birth</Form.Label> <br />
                <DatePicker
                  id="DOB"
                  selected={formik.values.DOB}
                  className="form-control"
                  onChange={(date) => formik.setFieldValue("DOB", date)}
                  showYearDropdown
                  showMonthDropdown
                  maxDate={new Date()}
                  onBlur={formik.handleBlur}
                />
                {formik.values.DOB &&
                  moment().diff(moment(formik.values.DOB), "years") < 18 && (
                    <React.Fragment>
                      <br />
                      <small class="text-danger">Age should above 18</small>
                    </React.Fragment>
                  )}
                {formik.values.DOB &&
                  moment().diff(moment(formik.values.DOB), "years") > 30 && (
                    <React.Fragment>
                      <br />
                      <small class="text-danger">Age should below 30</small>
                    </React.Fragment>
                  )}
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group as={Col} controlId="placeofbirth">
                <Form.Label>Place Of Birth</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Place Of Birth"
                  value={formik.values.placeofbirth}
                  onChange={(e) =>
                    formik.setFieldValue("placeofbirth", e.target.value)
                  }
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.touched.placeofbirth && formik.errors.placeofbirth
                  }
                />
                {formik.touched.placeofbirth && formik.errors.placeofbirth && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.placeofbirth}
                  </Form.Control.Feedback>
                )}
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group as={Col} controlId="gender">
                <Form.Label>Select Gender</Form.Label>
                <Form.Control as="select">
                  <option>Gender</option>
                  <option>Male</option>
                  <option>Female</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="weight">
              <Form.Label>Weight(kgs)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Your Weight"
                value={formik.values.weight}
                maxLength={2}
                onChange={(e) => formik.setFieldValue("weight", e.target.value)}
                onFocus={formik.handleBlur}
                isInvalid={formik.touched.weight && formik.errors.weight}
              />
              {formik.touched.weight && formik.errors.weight && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.weight}
                </Form.Control.Feedback>
              )}
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
            <Form.Group as={Col} controlId="height">
              <Form.Label>Height(cm)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Your Height"
                value={formik.values.height}
                maxLength={3}
                onChange={(e) => formik.setFieldValue("height", e.target.value)}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.height && formik.errors.height}
              />
              {formik.touched.height && formik.errors.height && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.height}
                </Form.Control.Feedback>
              )}
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>

            <Form.Group as={Col} controlId="chest">
              <Form.Label>Chest(cm)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter chest size"
                value={formik.values.chest}
                maxLength={3}
                onChange={(e) => formik.setFieldValue("chest", e.target.value)}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.chest && formik.errors.chest}
              />
              {formik.touched.chest && formik.errors.chest && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.chest}
                </Form.Control.Feedback>
              )}
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </Form.Row>
        </Form>
      </Container>
    </div>
  );
};
