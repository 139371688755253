import React, { useState } from "react";
import { Form, Row, Col, Button, Container, Card } from "react-bootstrap";
import "./Enrollment.styles.css";
import { FamilyMember } from "./FamilyMember";

export const FamilyDetails = () => {
  return (
    <React.Fragment>
      <FamilyMember selectedMember="Father" index="1" />
      <FamilyMember selectedMember="Mother" index="1" />
      <FamilyMember selectedMember="Brother" index="1" />
      <FamilyMember selectedMember="Sister" index="1" />
    </React.Fragment>
  );
};
